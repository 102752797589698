import { styled } from "styled-components";
import { COLORS } from "../../styles/constants";
import { Button } from "@mui/material";
import { OutOAuthUser } from "../../queries/model";
import Fonts from "../../components/Fonts";
interface Props {
  hidden?: boolean;
  me?: OutOAuthUser;
  onClickLogout?: () => void;
}

export const More = ({ hidden, me, onClickLogout = () => {} }: Props) => {
  return (
    <Container hidden={hidden}>
      <ProfileWrapper>
        <ProfileImage>
          {me?.name ? (
            <img style={{ width: 60, height: 60 }} src={me?.profileImage} />
          ) : (
            <EmptyProfileImage />
          )}
        </ProfileImage>
        <ProfileContext>
          <Fonts.H3>{me?.name} </Fonts.H3>
          <Fonts.Blockquote style={{ color: COLORS.gray500 }}>
            {me?.email}
          </Fonts.Blockquote>
        </ProfileContext>
      </ProfileWrapper>

      <Button
        disableRipple={true}
        disableElevation={true}
        onClick={onClickLogout}
        variant="outlined"
        sx={{
          color: COLORS.gray900,
          width: "100%",
          height: 54,
          border: `1px solid ${COLORS.gray200}`,
          marginTop: "20px",
          borderRadius: "8px",
          "&:hover": {
            borderColor: "transparent",
          },
        }}
      >
        로그아웃
      </Button>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 20px;
`;
const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
`;
const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;
const ProfileContext = styled.div``;
const EmptyProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${COLORS.gray600};
`;

export default More;
