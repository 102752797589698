import { SVGProps } from "react";

export const Delete = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66602 4.66602H13.3327"
        stroke="#D8204C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 7.33398V11.334"
        stroke="#D8204C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 7.33398V11.334"
        stroke="#D8204C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33398 4.66602L4.00065 12.666C4.00065 13.0196 4.14113 13.3588 4.39118 13.6088C4.64122 13.8589 4.98036 13.9993 5.33398 13.9993H10.6673C11.0209 13.9993 11.3601 13.8589 11.6101 13.6088C11.8602 13.3588 12.0007 13.0196 12.0007 12.666L12.6673 4.66602"
        stroke="#D8204C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
        stroke="#D8204C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
