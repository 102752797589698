export const WORKSPACE_ID = "workspaceId";
export const WORKSPACE_TOKEN = "workspaceToken";

export const saveItemToSession = (key: string, value: any) => {
  if (key && value) {
    value = typeof value === "object" ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, value);
  }
};

export const getItemFromSession = (key: string) => {
  return sessionStorage.getItem(key);
};
