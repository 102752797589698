import { SVGProps } from "react";

export const Later = ({
  width = 31,
  height = 30,
  stroke = "#ABB0BF",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9998 10H24.2498V3.75M23.8863 20.4462C22.7216 22.2398 21.0124 23.6127 19.0099 24.3634C17.0073 25.1142 14.8167 25.203 12.7599 24.6171C10.7031 24.0312 8.88863 22.8017 7.58227 21.1084C6.27592 19.4151 5.54698 17.3475 5.5022 15.2094C5.45741 13.0712 6.09879 10.975 7.33309 9.22852C8.5674 7.48202 10.3292 6.1775 12.3597 5.50598C14.3901 4.83447 16.5826 4.83124 18.6148 5.49744C20.6471 6.16364 22.4122 7.46368 23.6511 9.20695"
        stroke={stroke}
        strokeWidth="1.88"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
