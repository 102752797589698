import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import { Task } from "./pages/Task";
import toast, { Toaster } from "react-hot-toast";
import { COLORS } from "./styles/constants";
import { useEffect, useState } from "react";
import { getToken, saveWorkspaceToken } from "./utils/token";
import { OutOAuthUser } from "queries/model";
import {
  createWorkspaceV1AuthWorkspacesPost,
  getUserMeV1UsersMeGet,
  listWorkspacesV1AuthWorkspacesGet,
  userMeV1AuthMeGet,
} from "queries";
import { saveItemToSession, WORKSPACE_ID } from "utils/storage";
import styled from "@emotion/styled";

const Toast = () => (
  <Toaster
    toastOptions={{
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        padding: "8px 20px",
        boxSizing: "border-box",
        boxShadow: "0px 16px 24px 0px #1a1e2729",
      },
      success: {
        style: {
          background: COLORS.positive2,
        },
      },
      error: {
        style: {
          background: COLORS.negative2,
        },
      },
    }}
  />
);

const AppLayout = () => {
  const [me, setMe] = useState<OutOAuthUser>();
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const navigate = useNavigate();
  const token = getToken();
  useEffect(() => {
    (async () => {
      if (token) await reLogin();
      setIsAuthChecked(true);
    })();
  }, []);

  const reLogin = async () => {
    try {
      const [me, workspaces] = await Promise.all([
        userMeV1AuthMeGet(),
        listWorkspacesV1AuthWorkspacesGet(),
      ]);
      setMe(me);

      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || "");
        navigate("/task");
      } else {
        const createWorkspace = {
          name: me.name!,
        };
        const success = await createWorkspaceV1AuthWorkspacesPost(
          createWorkspace
        );
        if (success) toast.success("새로운 워크스페이스를 생성하였습니다.");
        else toast.error("새로운 워크스페이스 생성에 실패하였습니다.");
        navigate("/task");
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!isAuthChecked) return <></>;
  return token ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/signIn" />
  );
};

const App = () => {
  return (
    <>
      <Toast />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/signIn" />}></Route>
          <Route path="/signIn" element={<SignIn isSignIn={true} />}></Route>
          <Route path="/signUp" element={<SignIn isSignIn={false} />}></Route>
          <Route path="/task" element={<Task />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100%;
`;

export default App;
