import { SVGProps } from "react";

export const Plus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3V21.0002"
        stroke="white"
        strokeWidth="2.25003"
        strokeLinecap="round"
      />
      <path
        d="M21 12L2.99976 12"
        stroke="white"
        strokeWidth="2.25003"
        strokeLinecap="round"
      />
    </svg>
  );
};
