import { SVGProps } from "react";

export const Flag = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.45558 12.5182C1.25013 12.5182 1.0778 12.4486 0.938575 12.3094C0.799837 12.1707 0.730469 11.9986 0.730469 11.7931V0.916514C0.730469 0.711067 0.799837 0.538733 0.938575 0.399512C1.0778 0.260775 1.25013 0.191406 1.45558 0.191406H6.65822C6.82741 0.191406 6.97848 0.245789 7.11141 0.354555C7.24435 0.463321 7.32895 0.602301 7.3652 0.771492L7.54648 1.64162H10.882C11.0874 1.64162 11.2595 1.71099 11.3982 1.84973C11.5375 1.98895 11.6071 2.16128 11.6071 2.36673V8.16759C11.6071 8.37303 11.5375 8.54513 11.3982 8.68386C11.2595 8.82308 11.0874 8.89269 10.882 8.89269H7.12954C6.96035 8.89269 6.80929 8.83831 6.67635 8.72955C6.54341 8.62078 6.45882 8.4818 6.42256 8.31261L6.24129 7.44248H2.18068V11.7931C2.18068 11.9986 2.11132 12.1707 1.97258 12.3094C1.83336 12.4486 1.66102 12.5182 1.45558 12.5182ZM7.72776 7.44248H10.1569V3.09184H6.35005L6.06001 1.64162H2.18068V5.99227H7.43771L7.72776 7.44248Z"
        fill="#656871"
      />
    </svg>
  );
};
