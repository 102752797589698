import { useEffect, useState } from "react";
import styled from "styled-components";
import { Icons } from "../../components";
import Fonts from "../../components/Fonts";
import {
  createGoogleUserV1AuthGooglePost,
  createTaskV1WorksPost,
  createWorkspaceV1AuthWorkspacesPost,
  listWorkspacesV1AuthWorkspacesGet,
  listWorksV1WorksGet,
  removeTaskV1WorksWorkIdDelete,
  updateTaskV1WorksWorkIdPut,
  userMeV1AuthMeGet,
} from "../../queries";
import {
  CreateWork,
  ListWorksV1WorksGetParams,
  OutOAuthUser,
  OutWork,
  UpdateWork,
} from "../../queries/model";
import { COLORS } from "../../styles/constants";
import {
  Tab,
  Tabs,
  Button,
  TextField,
  IconButton,
  SwipeableDrawer,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ko from "date-fns/locale/ko";
import { koKR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import { Inbox } from "./Inbox";
import { More } from "./More";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { Later } from "./Later";
import {
  getToken,
  getWorkspaceToken,
  removeTokens,
  saveToken,
  saveWorkspaceToken,
} from "../../utils/token";
import { useNavigate, useLocation } from "react-router-dom";
import { saveItemToSession, WORKSPACE_ID } from "utils/storage";

export const Task = () => {
  const [me, setMe] = useState<OutOAuthUser | null>(null);
  const [date, setDate] = useState<string | undefined>(undefined);
  const [inboxTasks, setInboxTasks] = useState<OutWork[]>([]);
  const [laterTasks, setLaterTasks] = useState<OutWork[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [addTask, setAddTask] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string>("");
  const [taskContent, setTaskContent] = useState<string>("");
  const newId = uuidv4();
  const accessToken = getToken();
  const workspaceToken = getWorkspaceToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (accessToken && workspaceToken) {
      getWork();
      getMe();
    } else {
      login();
    }
  }, []);

  const login = async () => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("access_token");
    if (accessToken) {
      // const oAuth = { code: accessToken };
      // const token = await createGoogleUserV1AuthGooglePost(oAuth);
      // console.log(token);
      saveToken(accessToken);
      const [me, workspaces] = await Promise.all([
        userMeV1AuthMeGet(),
        listWorkspacesV1AuthWorkspacesGet(),
      ]);

      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || "");
        navigate("/task");
        getWork();
        getMe();
      } else {
        const createWorkspace = {
          name: me.name!,
        };
        const success = await createWorkspaceV1AuthWorkspacesPost(
          createWorkspace
        );
        if (success) toast.success("새로운 워크스페이스를 생성하였습니다.");
        else toast.error("새로운 워크스페이스 생성에 실패하였습니다.");
        navigate("/task");
        getWork();
        getMe();
      }
    } else {
      navigate("/signIn");
    }
  };

  // useEffect(() => {
  //   if (!token && !me?.name) {
  //     navigate("/signIn");
  //   } else {
  //     getWork();
  //     getMe();
  //   }
  // }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      const target = inboxTasks.find((item) => item.id === selectedTaskId);
      setDate(target?.dueDate!);
    } else if (tabIndex === 1) {
      const target = laterTasks.find((item) => item.id === selectedTaskId);
      setDate(target?.dueDate!);
    }
  }, [selectedTaskId]);

  const getWork = async () => {
    const inboxParams: ListWorksV1WorksGetParams = { section_type: "inbox" };
    const inboxData = await listWorksV1WorksGet(inboxParams);
    const laterParams: ListWorksV1WorksGetParams = { section_type: "someday" };
    const laterData = await listWorksV1WorksGet(laterParams);
    setInboxTasks(inboxData.tasks!);
    setLaterTasks(laterData.tasks!);
  };

  const getMe = async () => {
    const data = await userMeV1AuthMeGet();
    setMe(data);
  };

  const setFocusElement = (id: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
      const element = el.querySelector("input") as HTMLElement;
      element && element.focus();
    }, 100);
  };

  const handleClickAddTask = () => {
    setAddTask(true);
    setFocusElement("task-input");
  };

  const handleCloseAddTask = () => {
    setAddTask(false);
    setDate(undefined);
    setTimeout(() => {
      setSelectedTaskId("");
    }, 200);
    setTaskContent("");
  };

  const handleCreateTask = async (tabIndex: number) => {
    const work: CreateWork = {
      id: newId,
      type: "task",
      workSectionType: tabIndex === 1 ? "someday" : "inbox",
      content: taskContent,
      dueDate: date,
    };
    const success = await createTaskV1WorksPost(work);

    setAddTask(false);
    setDate(undefined);
    setSelectedTaskId("");
    setTaskContent("");
    if (success) {
      toast.success("새로운 태스크를 생성하였습니다.");
      getWork();
    }
  };

  const handleUpdateTask = async () => {
    const updateWork: UpdateWork = { content: taskContent, dueDate: date };
    const success = await updateTaskV1WorksWorkIdPut(
      selectedTaskId,
      updateWork
    );
    setAddTask(false);
    setDate(undefined);
    setTimeout(() => {
      setSelectedTaskId("");
    }, 200);
    setTaskContent("");
    if (success) {
      toast.success("태스크를 수정하였습니다.");
      getWork();
    }
  };

  const handleClickTask = async (work: OutWork) => {
    setTaskContent(work.content!);
    setSelectedTaskId(work.id);
    setAddTask(true);
    setFocusElement("task-input");
  };

  const handleClickLogout = () => {
    removeTokens();
    window.location.replace(`${window.location.origin}/signIn`);
  };

  const handleClickDueDate = () => {
    setAddTask(false);
    setCalendar(true);
  };

  const handleCloseDueDate = (noDate?: boolean) => {
    if (noDate) setDate(undefined);
    setCalendar(false);
    setAddTask(true);
    setFocusElement("task-input");
  };

  const handleClickCalendar = (newDate: dayjs.Dayjs) => {
    setDate(dayjs(newDate).format("YYYY-MM-DD"));
    setCalendar(false);
    setAddTask(true);
  };

  const handleClickDeleteDialog = () => {
    setDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => [setDeleteDialog(false)];

  const handleClickDelete = async () => {
    const success = await removeTaskV1WorksWorkIdDelete(selectedTaskId);
    setAddTask(false);
    setDeleteDialog(false);
    setDate(undefined);
    setTimeout(() => {
      setSelectedTaskId("");
    }, 200);
    setTaskContent("");

    if (success) {
      toast.success("태스크를 삭제하였습니다.");
      getWork();
    }
  };

  const handleClickLater = async () => {
    const updateWork: UpdateWork = { workSectionType: "someday" };
    const success = await updateTaskV1WorksWorkIdPut(
      selectedTaskId,
      updateWork
    );
    setAddTask(false);
    setDate(undefined);
    setTimeout(() => {
      setSelectedTaskId("");
    }, 200);
    setTaskContent("");
    if (success) {
      toast.success("태스크를 나중에로 옮겼습니다.");
      getWork();
    }
  };

  return (
    <Container>
      <MobileWrapper>
        <MobileMain>
          <Inbox
            tasks={inboxTasks}
            selectId={selectedTaskId}
            onClickTask={handleClickTask}
            hidden={!(tabIndex === 0)}
          ></Inbox>
          <Later
            tasks={laterTasks}
            selectId={selectedTaskId}
            onClickTask={handleClickTask}
            hidden={!(tabIndex === 1)}
          ></Later>
          <More
            me={me!}
            onClickLogout={handleClickLogout}
            hidden={!(tabIndex === 2)}
          ></More>
        </MobileMain>
        <div style={{ width: "100%" }}>
          {!(tabIndex === 2) && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                margin: "20px 0px",
                paddingRight: "20px",
                boxSizing: "border-box",
              }}
            >
              <IconButton
                onClick={handleClickAddTask}
                disableRipple
                sx={{
                  backgroundColor: COLORS.brand1,
                  borderRadius: "50%",
                  width: 48,
                  height: 48,
                }}
              >
                <Icons.Plus></Icons.Plus>
              </IconButton>
            </div>
          )}
          <SwipeableDrawer
            anchor="bottom"
            open={addTask}
            onClose={handleCloseAddTask}
            onOpen={function (event: React.SyntheticEvent<{}, Event>): void {
              throw new Error("Function not implemented.");
            }} // TransitionComponent={Transition}
            swipeAreaWidth={0}
            ModalProps={{
              keepMounted: false,
            }}
            className={selectedTaskId && "Selected-task"}
          >
            <DrawerContainer>
              <TaskContainer>
                <TextField
                  data-id="task-input"
                  autoFocus
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                  placeholder="태스크 제목을 입력해주세요."
                  value={taskContent}
                  onChange={(e) => {
                    setTaskContent(e.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: 16, color: COLORS.gray600 },
                  }}
                />
              </TaskContainer>
              <DrawerBtn>
                <div style={{ display: "flex" }}>
                  <Button
                    onClick={handleClickDueDate}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 32,
                      color: COLORS.gray600,
                      backgroundColor: COLORS.gray100,
                      borderRadius: 9,
                    }}
                  >
                    <Icons.Flag></Icons.Flag>
                    <Fonts.Blockquote style={{ marginLeft: 4 }}>
                      {date ? dayjs(date).format("MM월 DD일") : "기한 설정하기"}
                    </Fonts.Blockquote>
                  </Button>
                  {tabIndex === 0 && selectedTaskId && (
                    <IconButton
                      onClick={handleClickLater}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: COLORS.gray100,
                        borderRadius: 8,
                        marginLeft: 8,
                      }}
                    >
                      <Icons.Later></Icons.Later>
                    </IconButton>
                  )}
                  {selectedTaskId && (
                    <IconButton
                      onClick={handleClickDeleteDialog}
                      style={{
                        width: 32,
                        height: 32,
                        backgroundColor: COLORS.gray100,
                        borderRadius: 8,
                        marginLeft: 8,
                      }}
                    >
                      <Icons.Delete></Icons.Delete>
                    </IconButton>
                  )}
                </div>

                <IconButton
                  onClick={
                    selectedTaskId === ""
                      ? () => handleCreateTask(tabIndex)
                      : () => handleUpdateTask()
                  }
                  style={{ backgroundColor: COLORS.brand1 }}
                >
                  <Icons.Send></Icons.Send>
                </IconButton>
              </DrawerBtn>
            </DrawerContainer>
          </SwipeableDrawer>
          <SwipeableDrawer
            anchor="bottom"
            open={calendar}
            onClose={() => handleCloseDueDate()}
            onOpen={function (event: React.SyntheticEvent<{}, Event>): void {
              throw new Error("Function not implemented.");
            }} // TransitionComponent={Transition}
            swipeAreaWidth={0}
            style={{ borderTopRightRadius: 9, borderTopLeftRadius: 9 }}
            ModalProps={{
              keepMounted: false,
            }}
          >
            <DrawerContainer>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  koKR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={dayjs(date)}
                  onChange={(newDate) => handleClickCalendar(newDate!)}
                />
              </LocalizationProvider>
            </DrawerContainer>
            <Divider />
            <Button
              onClick={() => handleCloseDueDate(true)}
              style={{ color: COLORS.gray500, margin: "24px 0px" }}
            >
              지정 안함
            </Button>
          </SwipeableDrawer>
          <MobileFooter>
            <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
              <Tab
                icon={
                  <Icons.Inbox
                    stroke={tabIndex === 0 ? "#0039A7" : "#ABB0BF"}
                  />
                }
                label="인박스"
                style={{
                  minWidth: 106,
                  color: tabIndex === 0 ? COLORS.brand1 : COLORS.gray400,
                }}
              />
              <Tab
                icon={
                  <Icons.Later
                    stroke={tabIndex === 1 ? "#0039A7" : "#ABB0BF"}
                  />
                }
                label="나중에"
                style={{
                  minWidth: 106,
                  color: tabIndex === 1 ? COLORS.brand1 : COLORS.gray400,
                }}
              />
              <Tab
                icon={
                  <Icons.More fill={tabIndex === 2 ? "#0039A7" : "#ABB0BF"} />
                }
                label="더보기"
                style={{
                  minWidth: 106,
                  color: tabIndex === 2 ? COLORS.brand1 : COLORS.gray400,
                }}
              />
            </Tabs>
          </MobileFooter>
        </div>
        {/* 태스크 삭제 창 */}
        <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle id="alert-dialog-title">
            <Fonts.Body1 style={{ color: COLORS.negative1 }}>
              태스크를 삭제 하시겠습니까?
            </Fonts.Body1>
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteDialog}
              style={{ color: COLORS.brand1 }}
            >
              취소
            </Button>
            <Button
              onClick={handleClickDelete}
              style={{ color: COLORS.brand1 }}
            >
              삭제
            </Button>
          </DialogActions>
        </Dialog>
      </MobileWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* justify-content: center; */
  .MuiTabs-root {
    width: 100%;
  }
  .MuiTab-root {
    width: 33.3%;
  }
`;

const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MobileMain = styled.div`
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const MobileFooter = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid ${COLORS.gray300};
`;

const TaskContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  padding: 0px 16px;
`;

const DrawerContainer = styled.div`
  padding: 20px;
`;

const DrawerBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;
