import { SVGProps } from "react";

export const Send = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3078 13.6914L15.1539 8.84522M20.1113 5.8877L16.0207 19.1823C15.6541 20.3737 15.4706 20.9697 15.1544 21.1673C14.8802 21.3386 14.5406 21.3673 14.2419 21.2433C13.8975 21.1004 13.618 20.5423 13.0603 19.427L10.4694 14.2451C10.3809 14.0681 10.3366 13.98 10.2775 13.9033C10.225 13.8353 10.1645 13.774 10.0965 13.7215C10.0215 13.6637 9.93486 13.6204 9.76577 13.5359L4.57192 10.939C3.45662 10.3813 2.89892 10.1022 2.75601 9.75781C2.63207 9.45912 2.66033 9.11925 2.83169 8.845C3.02928 8.52876 3.62523 8.34505 4.81704 7.97834L18.1116 3.8877C19.0486 3.5994 19.5173 3.45537 19.8337 3.57155C20.1094 3.67275 20.3267 3.88986 20.4279 4.16553C20.544 4.48185 20.3999 4.95029 20.1119 5.88631L20.1113 5.8877Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
