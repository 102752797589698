import { SVGProps } from "react";

export const Inbox = ({
  width = 30,
  height = 30,
  stroke = "#ABB0BF",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10543_155096)">
        <path
          d="M5 17.5V22.5C5 23.8807 6.11929 25 7.5 25H22.5C23.8807 25 25 23.8807 25 22.5V17.5"
          stroke={stroke}
          strokeWidth="1.875"
          strokeLinecap="round"
        />
        <path
          d="M21.25 12.5L15 18.75L8.75 12.5"
          stroke={stroke}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 3.75L15 18.75"
          stroke={stroke}
          strokeWidth="1.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10543_155096">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
