import { useState } from "react";
import styled, { css } from "styled-components";
import { Icons } from "../../components";
import Fonts from "../../components/Fonts";
import { OutWork } from "../../queries/model";
import { COLORS } from "../../styles/constants";
import { useSwipeable } from "react-swipeable";
import dayjs from "dayjs";

interface Props {
  tasks?: OutWork[];
  selectId?: string;
  onClickTask?: (work: OutWork) => void;
  hidden?: boolean;
}

export const Later = ({
  tasks,
  selectId,
  onClickTask = () => {},
  hidden,
}: Props) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => console.log("Swiped left!"),
    onSwipedRight: () => console.log("Swiped right!"),
    onSwipedUp: () => console.log("Swiped up!"),
    onSwipedDown: () => console.log("Swiped down!"),
  });

  return (
    <Container hidden={hidden}>
      <TaskHeader>
        <Fonts.H3>태스크</Fonts.H3>
        <Fonts.H3 style={{ color: COLORS.gray400, marginLeft: 4 }}>
          ({tasks!.length})
        </Fonts.H3>
      </TaskHeader>
      {tasks!.map((v) => (
        <TaskWrapper
          key={v.id}
          {...handlers}
          onClick={() => onClickTask(v)}
          select={selectId === v.id ? "true" : "false"}
        >
          <Fonts.Body2
            className="task-content"
            // contentEditable={true}
            // suppressContentEditableWarning={true}
          >
            {v.content}
          </Fonts.Body2>
          {v.dueDate && selectId === v.id && (
            <TaskDueDate>
              <Icons.Flag></Icons.Flag>
              <Fonts.Blockquote className="task-duedate">
                {dayjs(v.dueDate).format("MM월 DD일")}
              </Fonts.Blockquote>
            </TaskDueDate>
          )}
        </TaskWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 20px;
`;

const TaskHeader = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

const TaskWrapper = styled.div<{ select?: string }>`
  border: 1px solid ${COLORS.gray200};
  border-radius: 9px;
  padding: 9px 17px;
  min-height: 20px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  ${(props) =>
    props.select === "true" &&
    css`
      border: 1px solid ${COLORS.brand1};
    `}
  .task-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      outline: none;
    }
  }
  .task-duedate {
    color: ${COLORS.gray600};
    margin-left: 8px;
  }
`;

const TaskDueDate = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: ${COLORS.gray100};
  border-radius: 9px;
  padding: 4px 9px;
  margin-top: 5px;
`;
