import { SVGProps } from "react";

export const More = ({
  width = 30,
  height = 30,
  fill = "#ABB0BF",
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="15" r="1.875" fill={fill} />
      <circle cx="15" cy="15" r="1.875" fill={fill} />
      <circle cx="22.5" cy="15" r="1.875" fill={fill} />
    </svg>
  );
};
