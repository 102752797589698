import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  CodeResponse,
  useGoogleLogin,
  UseGoogleLoginOptionsAuthCodeFlow,
} from "@react-oauth/google";
import { Login } from "./Login";
import Fonts from "../../components/Fonts";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Icons } from "../../components";
import {
  createGoogleUserByAppV1AuthGoogleGet,
  createGoogleUserV1AuthGooglePost,
  createWorkspaceV1AuthWorkspacesPost,
  getUserMeV1UsersMeGet,
  listWorkspacesV1AuthWorkspacesGet,
  userMeV1AuthMeGet,
} from "../../queries";
import { getToken, saveToken, saveWorkspaceToken } from "../../utils/token";
import { saveItemToSession, WORKSPACE_ID } from "../../utils/storage";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { OutOAuthUser, OutUserDetail, OutWorkspace } from "../../queries/model";
import { Logo } from "../../components/Icons/Logo";
import { COLORS } from "../../styles/constants";
// import { useAuthAction } from "hooks/useAuthAction";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  section.signin {
    white-space: pre-line;
    padding: 24px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.25px;
      margin-bottom: 20px;
    }
    footer {
      position: absolute;
      bottom: 80px;
    }
  }
  section.intro {
    flex: 3;
    overflow: hidden;
    background-color: #2d6adf;
  }
`;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

interface Props {
  isSignIn: boolean;
}

const GoogleLogInButton = ({
  label,
  options,
}: {
  label: string;
  options: UseGoogleLoginOptionsAuthCodeFlow;
}) => {
  const onHandleLoginGoogle = useGoogleLogin(options);
  return <Login onClick={onHandleLoginGoogle} label={label} />;
};

const SignIn = ({ isSignIn }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authToken, setAuthToken] = useState<string | null>(null);

  // useEffect(() => {
  //   login();
  // }, [authToken]);

  // const login = async (
  //   response?: Omit<CodeResponse, "error" | "error_description" | "error_uri">
  // ) => {
  //   try {
  //     const params = new URLSearchParams(location.search);
  //     const accessToken = params.get("access_token");
  //     setAuthToken(accessToken);
  //     // const oAuth = { code: authToken! };

  //     // const token: any = await createGoogleUserByAppV1AuthGoogleGet({
  //     //   code: oAuth.code!,
  //     //   state: "https://localhost:3000/task",
  //     // });

  //     saveToken(accessToken!);
  //     // console.log(token.accessToken);
  //     const [me, workspaces] = await Promise.all([
  //       userMeV1AuthMeGet(),
  //       listWorkspacesV1AuthWorkspacesGet(),
  //     ]);

  //     if (workspaces.length) {
  //       saveItemToSession(WORKSPACE_ID, workspaces[0].id);
  //       saveWorkspaceToken(workspaces[0].accessToken || "");
  //       navigate("/task");
  //     } else {
  //       const createWorkspace = {
  //         name: me.name!,
  //       };
  //       const success = await createWorkspaceV1AuthWorkspacesPost(
  //         createWorkspace
  //       );
  //       if (success) toast.success("새로운 워크스페이스를 생성하였습니다.");
  //       else toast.error("새로운 워크스페이스 생성에 실패하였습니다.");
  //       navigate("/task");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope:
      "openid email profile https://www.googleapis.com/auth/calendar.events",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/task`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  return (
    <Wrapper>
      <section className="signin">
        {/* <div className="pb-6">
          <Logo />
        </div> */}
        <div className="title">{"슬래시에 오신 것을\n환영합니다"}</div>
        <Fonts.Body1 style={{ color: COLORS.gray800 }}>
          {
            "슬래시는 여러분의 편리한 일정 관리를 위해\n구글 아이디를 사용하여 구글 캘린더를 연동합니다."
          }
        </Fonts.Body1>
        {isSignIn ? (
          <>
            <div style={{ margin: "28px 0px 28px 0px", width: "100%" }}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogInButton
                  options={googleLoginOptions}
                  label={"구글 계정으로 로그인"}
                />
              </GoogleOAuthProvider>
            </div>
            <Fonts.Blockquote style={{ display: "flex" }}>
              <div style={{ color: COLORS.gray600 }}>
                슬래시가 처음이신가요?
              </div>
              <div
                style={{
                  cursor: "pointer",
                  color: COLORS.brand1,
                  marginLeft: 8,
                }}
                onClick={() => navigate("/signUp")}
              >
                회원 가입하기
              </div>
            </Fonts.Blockquote>
          </>
        ) : (
          <>
            <div style={{ margin: "28px 0px 28px 0px", width: "100%" }}>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogInButton
                  options={googleLoginOptions}
                  label={"구글 계정으로 가입하기"}
                />
              </GoogleOAuthProvider>
            </div>
            <Fonts.Blockquote style={{ display: "flex" }}>
              <div style={{ color: COLORS.gray600 }}>이미 가입하셨나요?</div>
              <div
                style={{
                  cursor: "pointer",
                  color: COLORS.brand1,
                  marginLeft: 8,
                }}
                onClick={() => navigate("/signIn")}
              >
                로그인하기
              </div>
            </Fonts.Blockquote>
          </>
        )}
        {/* <footer>
          <Fonts.Blockquote style={{ cursor: "pointer", display: "flex" }}>
            <a href="/">이용약관</a>
            <div style={{ margin: "0px 8px" }}>|</div>
            <a
              href="http://policy.doslash.io/"
              target="_blank"
              rel="noreferrer"
            >
              개인 정보 취급 방침
            </a>
          </Fonts.Blockquote>
        </footer> */}
      </section>
    </Wrapper>
  );
};

export default SignIn;
